import React, { useState, useRef, useEffect } from 'react';
import { Spin, Button, Form, Input } from 'antd';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import { getProviderDetailTesting } from 'api/testing/provider';

type TFormInput = {
    providerLink: string;
};

const Index: React.FC = () => {
    const [form] = Form.useForm<TFormInput & { active: boolean }>();
    const [providerName, setProviderName] = useState('');
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
        try {
            const formValues = await form.validateFields();
            setLoading(true);
            setProviderName(
                formValues.providerLink.replace(/.*provider\/([^?]+).*/, '$1'),
            );
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    const drawProviderCredentialText = (
        ctx: CanvasRenderingContext2D,
        text: string,
        x: number,
        y: number,
    ) => {
        ctx.font = '32px Inter';
        ctx.fillStyle = '#000';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        const textMetrics = ctx.measureText(text);
        const textWidth = textMetrics.width;

        const padding = 16;
        const boxWidth = textWidth + padding * 2;
        const boxHeight = 86;
        const borderRadius = 10;

        const boxX = x - boxWidth / 2;
        const boxY = y - boxHeight / 2 - 2;

        ctx.beginPath();
        ctx.moveTo(boxX + borderRadius, boxY);
        ctx.lineTo(boxX + boxWidth - borderRadius, boxY);
        ctx.arc(
            boxX + boxWidth - borderRadius,
            boxY + borderRadius,
            borderRadius,
            -Math.PI / 2,
            0,
        );
        ctx.lineTo(boxX + boxWidth, boxY + boxHeight - borderRadius);
        ctx.arc(
            boxX + boxWidth - borderRadius,
            boxY + boxHeight - borderRadius,
            borderRadius,
            0,
            Math.PI / 2,
        );
        ctx.lineTo(boxX + borderRadius, boxY + boxHeight);
        ctx.arc(
            boxX + borderRadius,
            boxY + boxHeight - borderRadius,
            borderRadius,
            Math.PI / 2,
            Math.PI,
        );
        ctx.lineTo(boxX, boxY + borderRadius);
        ctx.arc(
            boxX + borderRadius,
            boxY + borderRadius,
            borderRadius,
            Math.PI,
            -Math.PI / 2,
        );

        ctx.fillStyle = '#d4e7fe';
        ctx.fill();

        ctx.font = '32px Inter';
        ctx.fillStyle = '#363434';
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';
        ctx.fillText(text, x, y + 6);
    };

    const drawProviderNameText = (ctx: CanvasRenderingContext2D, text: string, x: number, y: number) => {
        ctx.font = '36px Inter';
        ctx.fillStyle = '#000';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        const textMetrics = ctx.measureText(text);
        const textWidth = textMetrics.width;

        const padding = 16;
        const boxWidth = Math.max(textWidth + padding * 2, 420);
        const boxHeight = 86;
        const borderRadius = 10;

        const boxX = x - boxWidth / 2;
        const boxY = y - boxHeight / 2 - 2;

        ctx.beginPath();
        ctx.moveTo(boxX + borderRadius, boxY);
        ctx.lineTo(boxX + boxWidth - borderRadius, boxY);
        ctx.arc(
            boxX + boxWidth - borderRadius,
            boxY + borderRadius,
            borderRadius,
            -Math.PI / 2,
            0,
        );
        ctx.lineTo(boxX + boxWidth, boxY + boxHeight - borderRadius);
        ctx.arc(
            boxX + boxWidth - borderRadius,
            boxY + boxHeight - borderRadius,
            borderRadius,
            0,
            Math.PI / 2,
        );
        ctx.lineTo(boxX + borderRadius, boxY + boxHeight);
        ctx.arc(
            boxX + borderRadius,
            boxY + boxHeight - borderRadius,
            borderRadius,
            Math.PI / 2,
            Math.PI,
        );
        ctx.lineTo(boxX, boxY + borderRadius);
        ctx.arc(
            boxX + borderRadius,
            boxY + borderRadius,
            borderRadius,
            Math.PI,
            -Math.PI / 2,
        );

        ctx.fillStyle = '#0e62f4';
        ctx.fill();

        ctx.font = '36px Inter';
        ctx.fillStyle = '#FFFFFF';
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';
        ctx.fillText(text, x, y);
    };

    const drawProviderImage = (providerDetail: any) => {
        console.log('providerDetail: ', providerDetail);
        const canvas = canvasRef.current;
        const ctx = canvas?.getContext('2d');
        if (!providerDetail?.photo || !ctx) return;
        const avatarImage = new Image();
        avatarImage.src = providerDetail?.photo;

        avatarImage.onload = () => {
            ctx.save();
            ctx.beginPath();
            const x = 520 + 76;
            const y = 520 - 200;
            const radius = 260;
            ctx.arc(x, y, radius, 0, Math.PI * 2);
            ctx.clip();
            const size = radius * 2;
            const scale = Math.max(
                size / avatarImage.width,
                size / avatarImage.height,
            );
            const scaledWidth = avatarImage.width * scale * 0.96;
            const scaledHeight = avatarImage.height * scale * 0.96;

            const dx = x - radius + (size - scaledWidth) / 2;
            const dy = y - radius + (size - scaledHeight) / 2;
            ctx.drawImage(
                avatarImage,
                dx,
                dy,
                scaledWidth,
                scaledHeight,
            );
            ctx.restore();

            const image = new Image();
            image.src =
              'https://saas-dev-jp.s3.ap-northeast-1.amazonaws.com/test/fdea82b9-ff77-4072-af2a-32ac7cfd6beb.png';

            image.onload = () => {
                ctx?.drawImage(image, 0, 0, 1200, 760);
                drawProviderCredentialText(
                    ctx,
                    providerDetail?.credential?.split(',').join(', '),
                    x,
                    y + radius + 65,
                );
                const { firstName, lastName, middleName } = providerDetail;
                const arr = [firstName, middleName, lastName].filter(Boolean);
                drawProviderNameText(
                    ctx,
                    arr.join(' '),
                    x,
                    y + radius - 10,
                );
                setLoading(false);
            };
        };
    };

    const handleDraw = ({ providerDetail }: { providerDetail: any }) => {
        const canvas = canvasRef.current;
        const ctx = canvas?.getContext('2d');
        if (!ctx) return;
        ctx.fillStyle = '#ffffff';
        ctx?.fillRect(0, 0, 1200, 760);

        drawProviderImage(providerDetail);
        ctx?.stroke();
    };

    const getProviderDetail = async () => {
        const { data } = await getProviderDetailTesting({ slug: providerName });
        handleDraw({ providerDetail: data?.data });
    };

    useEffect(() => {
        if (providerName) {
            getProviderDetail();
        }
    }, [providerName]);

    return (
        <div className={s.wrap}>
            <div className={s.tips}>This is for generating a provider card</div>
            <div className={s.formWrap}>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ active: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <Form.Item
                        className={s.rowItem}
                        label="Provider link"
                        name="providerLink"
                        rules={[
                            {
                                required: true,
                                message: 'Provider link is required',
                            },
                        ]}
                    >
                        <Input
                            style={{ width: '500px' }}
                            placeholder="https://www.helloklarity.com/provider/cynthia-yu-1"
                        />
                    </Form.Item>
                </Form>
                <div className={s.providerName}>
                    {providerName ? `Provider name: ${providerName}` : ''}
                </div>
                <div className={s.buttonWrap}>
                    <Button type="primary" onClick={handleSave}>
                        Generate a image
                    </Button>
                </div>
            </div>

            <div className={s.line} />

            <div className={s.preview}>
                <div className={s.canvasWrap}>
                    <Spin spinning={loading}>
                        <canvas
                            ref={canvasRef}
                            width={1200}
                            height={760}
                        />
                    </Spin>
                </div>
            </div>
        </div>
    );
};

export default Index;
