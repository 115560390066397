export const hasNumber = (myString: string): boolean => {
    return /\d/.test(myString);
};

export const hasLowerCase = (myString: string): boolean => {
    return /[a-z]/.test(myString);
};

export const hasUpperCase = (myString: string): boolean => {
    return /[A-Z]/.test(myString);
};

export const hasSpecialCharacter = (myString: string): boolean => {
    // eslint-disable-next-line no-useless-escape
    const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    return regex.test(myString);
};

export const isValidEmail = (email: string): boolean => {
    if (email && email.includes(' ')) {
        return false;
    }
    // eslint-disable-next-line no-useless-escape
    const regex = /^((?!\.)[\w\-_.+]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
    return regex.test(email);
};

export const isValidUSPhone = (tel: string): boolean => {
    const regex = /^\+?1?[-. ]?\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;
    return regex.test(tel);
};

export const sortByProperty = (array: any[], prop: string) => {
    array.sort((a, b) => {
        try {
            const valueA = a[prop].toLowerCase();
            const valueB = b[prop].toLowerCase();

            if (valueA < valueB) {
                return -1;
            }

            if (valueA > valueB) {
                return 1;
            }
        } catch (e) {
            console.error(e);
        }

        return 0;
    });
    return array;
};

export const isValidUSPhoneV2 = (tel: string): boolean => {
    const regex = /^[0-9()\-\s]*$/;
    return regex.test(tel);
};