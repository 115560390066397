// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--_XS92 {
  padding: 20px;
}
.s-module__wrap--_XS92 .s-module__tips--UPglD {
  margin-bottom: 30px;
}
.s-module__wrap--_XS92 .s-module__canvasWrap--CPEAZ {
  width: 600px;
  height: 380px;
}
.s-module__wrap--_XS92 .s-module__canvasWrap--CPEAZ canvas {
  margin: 0;
  width: 100%;
  height: 100%;
}
.s-module__providerName--q4ENp {
  margin: 16px 0;
}
.s-module__preview--X_yBY {
  margin: 16px 0;
}
.s-module__downloadBtn--NHPne {
  margin-left: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/testing/Provider/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAFA;EAGQ,mBAAA;AAER;AALA;EAMQ,YAAA;EACA,aAAA;AAER;AATA;EASY,SAAA;EACA,WAAA;EACA,YAAA;AAGZ;AAEA;EACI,cAAA;AAAJ;AAGA;EACI,cAAA;AADJ;AAIA;EACI,iBAAA;AAFJ","sourcesContent":[".wrap {\n    padding: 20px;\n    .tips {\n        margin-bottom: 30px;\n    }\n    .canvasWrap {\n        width: 600px;\n        height: 380px;\n        canvas {\n            margin: 0;\n            width: 100%;\n            height: 100%;\n        }\n    }\n}\n\n.providerName {\n    margin: 16px 0;\n}\n\n.preview {\n    margin: 16px 0;\n}\n\n.downloadBtn {\n    margin-left: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--_XS92`,
	"tips": `s-module__tips--UPglD`,
	"canvasWrap": `s-module__canvasWrap--CPEAZ`,
	"providerName": `s-module__providerName--q4ENp`,
	"preview": `s-module__preview--X_yBY`,
	"downloadBtn": `s-module__downloadBtn--NHPne`
};
export default ___CSS_LOADER_EXPORT___;
